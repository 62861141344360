<template>
	<div>
		<common-table
				:itemScope="['lsell', 'meetingPlace', 'peitao']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				pageTitle="会场"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:meetingPlace="{ item }">
				<v-btn depressed color="primary" @click="meetingPlace(item.code)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					会议室
				</v-btn>
			</template>

			<template v-slot:peitao="{ item }">
				<v-btn depressed color="primary" @click="peitao(item)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					配套
				</v-btn>
			</template>
		</common-table>

		<template v-if="dialog">
			<exhibition-data-box
					:dialog.sync="dialog"
					title="选择会场配套"
					:width="412"
					:contentHeight="650"
			>
				<cascade-checkbox
						:height="600"
						@closeDialog="dialog = false"
						searchKey="MGKIT"
						:form="meetingObject"
						:api="api"/>
			</exhibition-data-box>
		</template>

		<template v-if="qrObject.dialog">
			<qr-code
					:dialog.sync="qrObject.dialog"
					:param="qrObject.param"
					:subtitle="qrObject.subtitle"
					:pathNo="qrObject.pathNo"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/meeting.js'
import qrCode from "@/components/qr-code/qr-code.vue"
import commonTag from "@/components/common-tag/index.vue"
import editorText from "@/components/editorText/editorText.vue"
import cascadeCheckbox from "@/components/cascadeCheckbox/cascadeCheckbox.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';

export default {
	mixins: [commonCurd],
	components: {
		qrCode,
		cascadeCheckbox,
		exhibitionDataBox
	},
	data() {
		return {
			api,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: '',
				pathNo: null
			},
			meetingObject: {},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			dialog: false,
			// 定义右侧按钮
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入会场代码/名称', property: 'searchkey'},
				{
					type: 'select',
					label: '状态',
					property: 'status',
					items: [{desc: '上架', code: true}, {desc: '不上架', code: false}]
				}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				},
				{
					event: 'phoneView',
					icon: 'erweima',
					color: 'primary',
					tooltip: '移动端'
				},
				// {
				// 	event: 'pcView',
				// 	icon: 'diannaoduan',
				// 	color: 'primary',
				// 	tooltip: '电脑端'
				// }
			],
			// 表格头部字段
			headers: [
				{text: '会场代码', value: 'code'},
				{text: '会场名称', value: 'description'},
				{text: '会议室', value: 'meetingPlace'},
				{text: '会议室总数', value: 'totalroom'},
				{text: '会场总面积', value: 'size'},
				{text: '容纳总人数', value: 'people'},
				{text: '参考价格', value: 'showprice'},
				{text: '停车位数', value: 'parking'},
				{text: '联系电话', value: 'tel'},
				{text: '会场配套', value: 'peitao'},
				{text: '排序号', value: 'seq'},
				{text: '状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "会场代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "会场名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'address',
						connector: {
							props: {
								required: true,
								label: "会场地址"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'tel',
						connector: {
							props: {
								required: true,
								label: "联系电话",
								rules: [
									v => !!v || '酒店电话不能为空',
									v => (/^1[3|4|5|7|8][0-9]\d{8}$/.test(v) || /^(\d{3,4}-)?\d{7,8}$/.test(v)) || '电话格式不正确',
								],
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'coordinate',
						connector: {
							props: {
								label: "地图坐标",
								required: true,
								hint: '可在网上搜索“拾取坐标系统”后获取目标坐标',
								persistentHint: true,
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'totalroom',
						connector: {
							props: {
								required: true,
								label: "会议室总数"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'size',
						connector: {
							props: {
								required: true,
								label: "会场总面积"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'people',
						connector: {
							props: {
								required: true,
								label: "容纳总人数"
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'showprice',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "参考价格"
					// 		}
					// 	}
					// },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'parking',
						connector: {
							props: {
								required: true,
								label: "停车位数"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'type',
						connector: {
							props: {
								required: true,
								label: "场地类型",
								items: [{code: 'theatre', desc: '剧场'}, {code: 'meeting', desc: '会场'}]
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'roomtype',
						connector: {
							props: {
								required: true,
								label: "活动类型",
								items: [],
								multiple: true,
								chips: true,
								arrayType: false
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'mlistpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '移动端列表图(686*320)',
					// 			fileType: 'meeting'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mslidepics',
						connector: {
							props: {
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'meeting'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端列表图(1264*713)',
					// 			fileType: 'meeting'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'slidepics',
					// 	connector: {
					// 		props: {
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端轮播图(680*340)',
					// 			fileType: 'meeting'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '会场标签'
							}
						}
					},
					{
						cols: 12,
						subassembly: commonTag,
						property: 'tags',
						connector: {
							props: {
								keyname: 'meetinggrouptags'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '会场简介'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'introduction',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '会场介绍'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'richtext'
					}
				]
			}
		}
	},
	methods: {
		// 会场
		meetingPlace(item) {
			this.$router.push({name: 'meetingPlace', query: {value: item}});
		},
		// 配套
		peitao(item) {
			this.meetingObject = item
			this.dialog = true
		},
		otherTableOperate(event, item) {
			if (event === 'phoneView') {
				this.qrObject = {
					dialog: true,
					param: item.code,
					subtitle: item.description,
					pathNo: 8
				}
			}
		}
	},
	created() {
		this.axios.post(this.select_data, {keyname: 'meettype'}).then(res => {
			this.listDeploy.row[10].connector.props.items = res.data[0].values
		})
	}
}
</script>

<style scoped lang="scss">

</style>
